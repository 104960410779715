import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "flex max-w-[1560px] lg:mx-auto xl:justify-center m-0 h-screen"
};
import { onMounted } from "vue";
import { computed } from "vue";
import { useAppStore } from "@/stores/index";
import { useMeta } from "@/composables/use-meta";
import appLayout from "@/layout/app-layout.vue";
export default {
  __name: 'App',
  setup: function setup(__props) {
    var store = useAppStore();

    // meta
    useMeta({
      title: "Sales Admin"
    });
    onMounted(function () {
      document.getElementById("loaderID").style.display = "none";
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("main", _hoisted_1, [_createElementVNode("div", {
        "class": _normalizeClass(["main-section antialiased relative text-sm font-normal", [_unref(store).sidebar ? 'toggle-sidebar' : '', _unref(store).menu, _unref(store).layout, _unref(store).rtlClass]])
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent(appLayout)))], 2)]);
    };
  }
};