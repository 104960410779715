import { h, createApp, reactive } from "vue";
import singleSpaVue from "single-spa-vue";
import router from "./router";
import App from "./App.vue";
import "vue3-quill/lib/vue3-quill.css";
import 'primevue/resources/themes/aura-light-green/theme.css'

// prime vue resources 
import PrimeVue from 'primevue/config';
import Button from "primevue/button"
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice';
import Badge from 'primevue/badge';

// main app css
require("./assets/css/app.css");

// coisas do index.html
require("@/main.ts");

// URLs of the resources to load
const urls = [
  "https://fonts.googleapis.com",
  "https://fonts.gstatic.com",
  "https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600;700&display=swap",
  "https://kit.fontawesome.com/d5abab5315.js",
];

// Function to load a CSS file
function loadCSS(url: string) {
  let link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = url;
  document.head.appendChild(link);
}

// Function to load a JavaScript file
function loadJS(url: string) {
  let script = document.createElement("script");
  script.src = url;
  document.body.appendChild(script);
}

// Load the resources
for (let url of urls) {
  // @ts-ignore
  if (url.endsWith(".js")) {
    loadJS(url);
  } else {
    loadCSS(url);
  }
}

// import { createGtm } from "@gtm-support/vue-gtm";
import { abilitiesPlugin } from "@casl/vue";
import { createDynamicForms } from "@mathmore0000/vue-dynamic-forms";
// perfect scrollbar
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
// popper
import Popper from "vue3-popper";

import { useAppStore } from "./stores/app-store";

import { createPinia } from "pinia";
import { ability } from "./service/ability";

import { vMaska } from 'maska';

const module = "template";

const vueLifecycles = (singleSpaVue as any)({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      });
    },
  },
  async handleInstance(app) {
    await importSharedDependencies(app);

    const pinia = createPinia();
    const VueDynamicForms = createDynamicForms();

    app.config.globalProperties.$appState = reactive({
      isRTL: false,
      isNewThemeLoaded: false,
      layoutMode: "light",
    });

    app.use(pinia);
    app.use(router);
    app.use(PrimeVue);

    app.component('Button', Button);
    app.component('FileUpload', FileUpload);
    app.component('Toast', Toast);
    app.component('ProgressBar', ProgressBar);
    app.component(Badge);
    app.use(ToastService);

    app.provide("appStore", useAppStore());

    // VISTRO resources
    app.use(PerfectScrollbar);
    app.component("Popper", Popper);

    //input mask
    app.directive("maska", vMaska)
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

export async function importSharedDependencies(app) {
  try {
    // @ts-ignore
    const { pinia } = await import("components/main");
  } catch (error) {
    console.error("Failed to import shared dependencies:", error);
    throw error; // You can handle or propagate the error as needed
  }
}

export { module };
