import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: { name: "home" },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/plan/:cod",
    name: "plan",
    component: () => import("@/views/Plan.vue"),
  },
  {
    path: "/plan/:cod/task/:id",
    name: "task",
    component: () => import("@/views/Task.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  next();
});

export default router;
